import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  to: string;
  icon: React.ReactElement;
  name: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: '1px solid #edf1f7',
    color: '#8f9bb3',
    '&:hover': {
      color: '#598bff',
    },
  },
  icon: {
    color: 'inherit',
  },
  selected: {
    position: 'relative',
    backgroundColor: 'transparent !important',
    color: '#36f',
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      height: '100%',
      width: '4px',
      background: '#36f',
    },
    '&:before:hover': {
      background: '#598bff',
    },
  },
}));

const DrawerLink: React.FC<Props> = ({ to, name, icon }) => {
  const classes = useStyles();
  let match = useRouteMatch(to);
  return (
    <Link to={to} className={classes.root}>
      <ListItem selected={match?.isExact} classes={{ root: classes.root, selected: classes.selected }}>
        <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
        <ListItemText primary={name} />
      </ListItem>
    </Link>
  );
};

export default DrawerLink;
