import React, { Suspense } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';

// import BottomNav from './bottom-nav';
import Header from './header';
import Drawer from './drawer';
import DrawerProvider from './drawer-provider';
import FullScreenLoading from '../../components/loading/fullscreen-loading';
import { useProcessingIndicator } from '../../context/processing';
import BackDropWithIndicator from '../../components/loading/backdrop-with-indicator';

const useStyles = makeStyles((theme) => ({
  main: {
    // width: '100wh',
    paddingTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    flexGrow: 1,
    paddingLeft: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(8) + 1,
    },
  },
}));

const BaseLayout: React.FC = ({ children }) => {
  const classes = useStyles();
  const { isProcessing } = useProcessingIndicator();
  return (
    <DrawerProvider>
      <CssBaseline />
      <Header />
      <Drawer />
      <Suspense fallback={<FullScreenLoading />}>
        <main className={classes.main}>
          {children}
          <BackDropWithIndicator isOpen={isProcessing} />
        </main>
      </Suspense>
    </DrawerProvider>
  );
};

export default BaseLayout;
