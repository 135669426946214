import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useApolloClient } from '@apollo/react-hooks';

import config from '../../config';
import { useAuth } from '../../context/auth';
import { useDrawer } from './drawer-provider';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: '#000',
  },
  logoutIcon: {
    color: theme.palette.warning.main,
  },
  appBar: {
    padding: theme.spacing(0, 1),
    zIndex: theme.zIndex.drawer + 1,
  },
  offset: theme.mixins.toolbar,
  title: {
    paddingLeft: theme.spacing(1),
    flexGrow: 1,
  },
}));

const Header: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const client = useApolloClient();
  const { logout } = useAuth();
  const { toggle } = useDrawer();
  const handleLogout = () => {
    client.clearStore();
    logout(() => history.replace('/login'));
  };
  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar disableGutters>
          <IconButton onClick={toggle}>
            <MenuIcon className={classes.icon} />
          </IconButton>
          <Typography variant="h5" color="inherit" noWrap className={classes.title}>
            <Link component={RouterLink} to="/" color="textPrimary" underline="none">
              {config.app.shopName}
            </Link>
          </Typography>
          <IconButton onClick={handleLogout}>
            <Tooltip title="Logout">
              <LogoutIcon className={classes.logoutIcon} />
            </Tooltip>
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={classes.offset} />
    </>
  );
};

export default Header;
