import React from 'react';

type Value = { isProcessing: boolean; setProcessing: (isProcessing: boolean) => void };

const ProcessingContext = React.createContext<Value | undefined>(undefined);

type ProcessingProviderProps = { children: React.ReactNode };

const useProcessingIndicator = () => {
  const context = React.useContext(ProcessingContext);
  if (!context) {
    throw new Error(`useProcessing must be used within a ProcessingProvider`);
  }
  return context;
};
const ProcessingProvider = ({ children }: ProcessingProviderProps) => {
  const [isProcessing, setProcessing] = React.useState(false);
  const value = React.useMemo(
    () => ({
      isProcessing,
      setProcessing,
    }),
    [isProcessing],
  );
  return <ProcessingContext.Provider value={value}>{children}</ProcessingContext.Provider>;
};

export { useProcessingIndicator, ProcessingProvider };
