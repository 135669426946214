import React from 'react';
import { useAuth, User } from './auth';

const UserContext = React.createContext<User | null>(null);

const UserProvider: React.FC = (props) => <UserContext.Provider value={useAuth().user} {...props} />;

export const useUser = () => React.useContext(UserContext);

export default UserProvider;
