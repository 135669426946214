import jwtDecode from 'jwt-decode';
import Storage from './storage';
import { client } from '../config/apollo';
import { LOGIN, REFRESH_TOKEN } from '../graphql/user';
import { User } from '../context/auth';

export const initialStateOfUser = (): User | null => {
  let _token = Storage.getAuthToken();
  return getUserFromToken(_token) ?? null;
};

export const getUserFromToken = (token?: string): User | null => {
  if (token !== undefined) {
    const decoded: any = jwtDecode(token);
    return { id: decoded.id, role: decoded.role, name: decoded.name, shop: decoded.shop };
  }
  return null;
};

export const getExpireTimeFromToken = (token?: string): Date => {
  const expires = new Date(0);
  if (token) {
    const decodedToken: any = jwtDecode(token);
    expires.setUTCSeconds((decodedToken as any).exp);
  }
  return expires;
};

interface LoginResult {
  token: string;
  refreshToken?: string;
}

export const loginRequest = async (email: string, password: string): Promise<LoginResult | null> => {
  const response = await client.mutate({ mutation: LOGIN, variables: { input: { email, password } } });
  if (response.data) {
    return { token: response.data?.auth?.token, refreshToken: response.data?.auth?.refreshToken };
  }
  return null;
};

export const refreshTokenRequest = async (token: string): Promise<LoginResult | null> => {
  const response = await client.mutate({ mutation: REFRESH_TOKEN, variables: { token } });
  if (response.data) {
    return { token: response.data?.auth?.token, refreshToken: response.data?.auth?.refreshToken };
  }
  return null;
};
