const config = {
  app: {
    domain: process.env.REACT_APP_DOMAIN || 'localhost:3000',
    shopName: process.env.REACT_APP_SHOP_NAME || 'Genius Delivery Admin',
    graphQLEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT || 'http://localhost:4002/graphql',
    translationEndpoint: process.env.REACT_APP_TRANSLATION_ENDPOINT || 'http://localhost:3003',
    refreshInterval: 1000 * 60,
    version: process.env.REACT_APP_VERSION ?? 'Pandemic Version',
    cacheAge: process.env.REACT_APP_S3_CACHE_CONTROL ?? '31536000',
  },
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN ?? '',
  },
};

export default config;
