import React from 'react';

interface Props {
  color?: React.CSSProperties['color'];
  width?: React.CSSProperties['width'];
  height?: React.CSSProperties['height'];
}

const GeniusLogo: React.FC<Props> = ({ color, width, height }) => {
  return (
    <svg viewBox="0 0 1000 1000" width={width} height={height}>
      <defs>
        <style>{`.logo_svg__a{fill:${color}}`}</style>
      </defs>
      <path
        className="logo_svg__a"
        d="M12.74 146.14L986.42 7.27v246.08l-590.44 39.21v188.17H12.74V146.14zM11.97 662.76l416.25 37.65V508.19l555.84 7.99v475.04L11.97 835.06v-172.3z"
      />
    </svg>
  );
};

GeniusLogo.defaultProps = {
  color: '#006c92',
  width: '1em',
  height: '1em',
};

export default GeniusLogo;
