import { gql } from 'apollo-boost';

export const LOGIN = gql`
  mutation Login($input: UserLoginInput!) {
    auth: login(input: $input) {
      token
      refreshToken
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation RefreshToken($token: String!) {
    auth: refreshToken(token: $token) {
      token
      refreshToken
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`;

export const REQUEST_VERIFICATION = gql`
  mutation RequestVerification($user: String!) {
    requestVerification(user: $user)
  }
`;

export const VERIFY_AND_SET_PASSWORD = gql`
  mutation VerifyAndSetPassword($input: EmailVerificationInput!) {
    verifyAccount(input: $input)
  }
`;
