import React from 'react';
import { default as MaterialDrawer } from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useDrawer } from './drawer-provider';
import Backdrop from '@material-ui/core/Backdrop';
import { DrawerLinks, AdminLinks } from '../../routes/drawer-links';
import DrawerLink from '../../components/routing/drawer-link';
import { useUser } from '../../context/user';

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  list: {
    justifyContent: 'flex-end',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxShadow: '0 0.5rem 1rem 0 rgba(44, 51, 73, .1)',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    // width: theme.spacing(0),
    // visibility: 'hidden',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8) + 1,
      // visibility: 'visible',
      display: 'flex',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer - 1,
  },
}));

const Drawer: React.FC = () => {
  const classes = useStyles();
  const user = useUser();
  const { open, toggle, setOpen } = useDrawer();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MaterialDrawer
        anchor="left"
        open={open}
        onClose={toggle}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}>
        <Toolbar />
        <div className={classes.list} role="presentation" onClick={handleClose}>
          <Divider />
          <List>
            {DrawerLinks.map((link) => (
              <DrawerLink key={link.id} to={link.to} name={link.name} icon={link.icon} />
            ))}
          </List>
          {user?.role === 'ADMIN' ? (
            <>
              <Divider />
              <List>
                {AdminLinks.map((link) => (
                  <DrawerLink key={link.id} to={link.to} name={link.name} icon={link.icon} />
                ))}
              </List>
            </>
          ) : null}
        </div>
      </MaterialDrawer>
      <Backdrop open={open} onClick={toggle} className={classes.backdrop} />
    </>
  );
};

export default Drawer;
