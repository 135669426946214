import * as React from 'react';
import { AuthContext, User } from '../context/auth';
import Storage, { StorageEvent } from './storage';
import { initialStateOfUser, getUserFromToken, loginRequest, refreshTokenRequest } from './helper';
import UserProvider from '../context/user';

const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = React.useState<User | null>(initialStateOfUser());
  const [isAuthenticated, setAuthenticated] = React.useState<boolean>(user !== null ? true : false);

  React.useEffect(() => {
    const refreshTokens = async () => {
      const _token = getToken();
      const _refreshToken = Storage.getRefreshToken();
      if (_token === undefined && _refreshToken !== undefined) {
        const res = await refreshTokenRequest(_refreshToken);
        if (res !== null) {
          saveToken(res?.token, res?.refreshToken);
        }
      }
    };
    refreshTokens();
  }, []);

  React.useEffect(() => {
    Storage.attach(onStorageUpdate);
    return () => Storage.detach(onStorageUpdate);
  }, []);

  const onStorageUpdate = (event: StorageEvent) => {
    console.log(event);
    switch (event) {
      case StorageEvent.SAVE_TOKEN:
        setUser(getUserFromToken(Storage.getAuthToken()));
        setAuthenticated(true);
        return;
      case StorageEvent.REMOVE_TOKEN:
        setUser(null);
        setAuthenticated(false);
    }
  };

  const saveToken = (token?: string, refreshToken?: string) => {
    if (token) {
      Storage.saveTokens(token, refreshToken);
      // setUser(getUserFromToken(token));
      // setAuthenticated(true);
    }
  };

  const login = async (telNo: string, password: string): Promise<boolean> => {
    try {
      const response = await loginRequest(telNo, password);
      console.log(response);
      if (response !== null) {
        saveToken(response.token, response.refreshToken);
        return true;
      }
      setUser(null);
      setAuthenticated(false);
    } catch (err) {
      console.error(err);
      setAuthenticated(false);
    }
    return false;
  };

  const logout = (cb?: () => void) => {
    Storage.removeAuth();
    // setAuthenticated(false);
    // setUser(null);
    if (cb) {
      cb();
    }
  };

  const getToken = () => Storage.getAuthToken();

  const refreshTokens = async (): Promise<void> => {
    const _token = getToken();
    const _refreshToken = Storage.getRefreshToken();
    if (_token === undefined && _refreshToken !== undefined) {
      const res = await refreshTokenRequest(_refreshToken);
      if (res !== null) {
        saveToken(res?.token, res?.refreshToken);
      } else {
        logout();
      }
    }
  };

  return (
    <AuthContext.Provider value={{ user, isAuthenticated, login, logout, getToken, saveToken, refreshTokens }}>
      <UserProvider>{children}</UserProvider>
    </AuthContext.Provider>
  );
};

export { AuthProvider };
