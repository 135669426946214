import React from 'react';
import { useAuth } from './context/auth';
import PublicApp from './public-app';
import OwnerApp from './owner-app';

const TheApp: React.FC = () => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <PublicApp />;
  }

  // if (user?.role !== 'ADMIN') {
  return <OwnerApp />;
  // }
  // return <>{'ADMIN ON DEVELEPMENT'}</>;
};

export default TheApp;
