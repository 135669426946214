import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { privateRoutes } from './routes';
import Layout from './containers/layout';
import PrivateRoute from './components/routing/private-route';

const OwnerApp: React.FC = () => {
  return (
    <Layout>
      <Switch>
        {privateRoutes.map((route, index) => (
          <PrivateRoute key={index} path={route.path} exact={route.exact} children={<route.component />} />
        ))}
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    </Layout>
  );
};

export default OwnerApp;
