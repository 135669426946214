import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PublicRoute from './components/routing/public-route';
import { authRoutes } from './routes';
import AuthLayout from './containers/layout/auth-layout';

const PublicApp: React.FC = () => {
  return (
    <AuthLayout imageCategory="grocery">
      <Switch>
        {authRoutes.map((route, index) => (
          <PublicRoute key={index} path={route.path} exact={route.exact} children={<route.component />} />
        ))}
        <Route>
          <Redirect to="/login" />
        </Route>
      </Switch>
    </AuthLayout>
  );
};

export default PublicApp;
