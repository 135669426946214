import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import config from '../../config';

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {`Copyright © `}
      <Link color="inherit" href="https://geniussoft.lk/">
        Genius Soft Pvt Ltd.
      </Link>{' '}
      {new Date().getFullYear()}
      {`. Ver.${config.app.version.toLowerCase()}`}
    </Typography>
  );
};

export default Copyright;
