import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import BaseLayout from './base-layout';
import { ProcessingProvider } from '../../context/processing';

const Layout: React.FC = ({ children }) => {
  return (
    <ProcessingProvider>
      <CssBaseline />
      <BaseLayout> {children} </BaseLayout>
    </ProcessingProvider>
  );
};

export default Layout;
