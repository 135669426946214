import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuth } from '../../context/auth';

interface Props extends RouteProps {}

const PublicRoute: React.FC<Props> = ({ children, ...rest }) => {
  const { isAuthenticated, refreshTokens } = useAuth();

  React.useEffect(() => {
    const refreshToken = async () => {
      await refreshTokens();
    };
    refreshToken();
  }, [refreshTokens]);

  return <Route {...rest} render={() => (!isAuthenticated ? children : <Redirect to="/" />)} />;
};

export default PublicRoute;
