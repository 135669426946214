import * as React from 'react';

export interface User {
  id: string;
  name: string;
  shop: string;
  role: string;
}

interface AuthContext {
  user: User | null;
  isAuthenticated: boolean;
  login: (email: string, password: string) => Promise<boolean>;
  logout: (cb?: () => void) => void;
  saveToken: (token?: string, refreshToken?: string) => void;
  getToken: () => string | undefined;
  refreshTokens: () => Promise<void>;
}

export const AuthContext = React.createContext<AuthContext | undefined>(undefined);

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};
