import React, { lazy } from 'react';
import Login from '../containers/login/login';

const Dashboard = lazy(() => import('../containers/dashboard'));
const ItemManagement = lazy(() => import('../containers/item-management'));
const AddItem = lazy(() => import('../containers/item-management/add-item'));
const EditItem = lazy(() => import('../containers/item-management/edit-item'));
const UserManagement = lazy(() => import('../containers/user-management'));
const AddUser = lazy(() => import('../containers/user-management/add-user'));
const UserProfile = lazy(() => import('../containers/user-management/profile'));
const OrderManagement = lazy(() => import('../containers/orders'));
const CategoryManagement = lazy(() => import('../containers/category-management'));
const AddCategory = lazy(() => import('../containers/category-management/add-category'));
const EditCategory = lazy(() => import('../containers/category-management/edit-category'));
const ForgotPassword = lazy(() => import('../containers/forgot-password'));
const ResetPassword = lazy(() => import('../containers/reset-password'));
const OrderView = lazy(() => import('../containers/orders/order-view'));
const ProcessOrder = lazy(() => import('../containers/orders/process-order'));
const VerifyAccount = lazy(() => import('../containers/verify-account'));
const CompleteVerification = lazy(() => import('../containers/complete-verification'));
const ShopManagement = lazy(() => import('../containers/shop-management'));
const EditShop = lazy(() => import('../containers/shop-management/edit-shop'));
const MyShop = lazy(() => import('../containers/shop-management/my-shop'));
const InvoiceManagement = lazy(() => import('../containers/invoice'));
const InvoiceView = lazy(() => import('../containers/invoice/invoice-view'));
const CustomerManagement = lazy(() => import('../containers/customer'));

export const privateRoutes = [
  {
    path: '/',
    exact: true,
    component: () => <Dashboard />,
    parent: null,
  },
  {
    path: '/orders',
    exact: true,
    component: () => <OrderManagement />,
    parent: null,
  },
  {
    path: '/orders/:orderId',
    exact: true,
    component: () => <OrderView />,
    parent: null,
  },
  {
    path: '/orders/:orderId/process',
    exact: true,
    component: () => <ProcessOrder />,
    parent: null,
  },
  {
    path: '/invoices',
    exact: true,
    component: () => <InvoiceManagement />,
    parent: null,
  },
  {
    path: '/invoices/:invoiceId',
    exact: true,
    component: () => <InvoiceView />,
    parent: null,
  },
  {
    path: '/item-management',
    exact: true,
    component: () => <ItemManagement />,
    parent: null,
  },
  {
    path: '/item-management/add',
    exact: true,
    component: () => <AddItem />,
    parent: null,
  },
  {
    path: '/item-management/edit',
    exact: true,
    component: () => <EditItem />,
    parent: null,
  },
  {
    path: '/category-management',
    exact: true,
    component: () => <CategoryManagement />,
    parent: null,
  },
  {
    path: '/category-management/add',
    exact: true,
    component: () => <AddCategory />,
    parent: null,
  },
  {
    path: '/category-management/edit',
    exact: true,
    component: () => <EditCategory />,
    parent: null,
  },

  {
    path: '/user-management',
    exact: true,
    component: () => <UserManagement />,
    parent: null,
  },
  {
    path: '/user-management/add',
    exact: true,
    component: () => <AddUser />,
    parent: null,
  },
  {
    path: '/profile',
    exact: true,
    component: () => <UserProfile />,
    parent: null,
  },

  {
    path: '/my-shop',
    exact: true,
    component: () => <MyShop />,
    parent: null,
  },
  {
    path: '/customer-management',
    exact: true,
    component: () => <CustomerManagement />,
    parent: null,
  },

  {
    path: '/shop-management',
    exact: true,
    component: () => <ShopManagement />,
    parent: null,
  },
  {
    path: '/shop-management/edit',
    exact: true,
    component: () => <EditShop />,
    parent: null,
  },
];

export const routesByParent = (parent: string | null) => privateRoutes.filter((route) => route.parent === parent);

export const authRoutes = [
  {
    path: '/login',
    exact: true,
    component: () => <Login />,
    parent: null,
  },
  {
    path: '/forgot-password',
    exact: true,
    component: () => <ForgotPassword />,
    parent: null,
  },
  {
    path: '/reset-password',
    exact: true,
    component: () => <ResetPassword />,
    parent: null,
  },
  {
    path: '/verify-account',
    exact: true,
    component: () => <VerifyAccount />,
    parent: null,
  },

  {
    path: '/complete-verification',
    exact: true,
    component: () => <CompleteVerification />,
    parent: null,
  },
];
