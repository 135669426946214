import * as Cookies from 'es-cookie';
import jwtDecode from 'jwt-decode';

const secure = process.env.NODE_ENV === 'production';

export enum StorageEvent {
  SAVE_TOKEN = 'SAVE_TOKEN',
  REMOVE_TOKEN = 'REMOVE_TOKEN',
}

interface Observable {
  notify: (event?: StorageEvent) => void;
  attach: (observer: any) => void;
  detach: (observer: any) => void;
}

class Storage implements Observable {
  private key = '__GENIUS_DELIVERY_TOKEN__';
  private rKey = '__GENIUS_DELIVERY_REFRESH_TOKEN__';

  private static instance: Storage;

  private storageObservers: Function[] = [];

  private constructor() {}

  public attach = (observer: Function) => {
    const isExist = this.storageObservers.includes(observer);
    if (!isExist) {
      this.storageObservers.push(observer);
    }
  };

  public detach(observer: Function): void {
    const observerIndex = this.storageObservers.indexOf(observer);
    if (observerIndex !== -1) {
      this.storageObservers.splice(observerIndex, 1);
    }
  }

  public notify(event?: StorageEvent): void {
    for (const observer of this.storageObservers) {
      observer(event);
    }
  }

  public static getInstance(): Storage {
    if (!Storage.instance) {
      Storage.instance = new Storage();
    }

    return Storage.instance;
  }

  private isTokenExpired = (token: string): boolean => {
    if (!token) {
      return true;
    }
    const decoded = jwtDecode(token);
    const current_time = Date.now().valueOf() / 1000;
    if (current_time > (decoded as any).exp) {
      return true;
    }
    return false;
  };

  private getExpireTimeFromToken = (token?: string): Date => {
    const expires = new Date(0);
    if (token) {
      const decodedToken: any = jwtDecode(token);
      expires.setUTCSeconds((decodedToken as any).exp);
    }
    return expires;
  };

  public saveTokens = (token: string, refreshToken?: string) => {
    if (token === undefined) {
      return;
    }
    Cookies.set(this.key, token, { expires: this.getExpireTimeFromToken(token), secure });
    if (refreshToken !== undefined)
      Cookies.set(this.rKey, refreshToken, { expires: this.getExpireTimeFromToken(refreshToken), secure });
    this.notify(StorageEvent.SAVE_TOKEN);
  };

  public removeAuth = () => {
    Cookies.remove(this.key);
    Cookies.remove(this.rKey);
    this.notify(StorageEvent.REMOVE_TOKEN);
  };

  public isTokenExists = (): boolean => {
    const token = Cookies.get(this.key);

    return token !== undefined && !this.isTokenExpired(token);
  };

  public isRefreshTokenExists = (): boolean => {
    const refreshToken = Cookies.get(this.rKey);
    return refreshToken !== undefined && !this.isTokenExpired(refreshToken);
  };

  public getAuthToken = (): string | undefined => {
    return Cookies.get(this.key);
  };

  public getRefreshToken = (): string | undefined => {
    return Cookies.get(this.rKey);
  };
}

export default Storage.getInstance();
