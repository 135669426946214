import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
// import { notoSerifSinhalaBold, notoSerifSinhalaRegular } from './noto-serif';

const getTheme = () => {
  const theme = createMuiTheme({
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
    palette: {
      // type: 'dark',
      primary: { main: '#36f', dark: '#598bff' },
      secondary: { main: '#8f9bb3' },
      success: { main: '#00d68f' },
      info: { main: '#0095ff' },
      warning: { main: '#fa0' },
      error: { main: '#ff3d71' },
      text: {
        primary: '#1a2138',
        secondary: '#8f9bb3',
        hint: '#8f9bb3',
      },
      action: {
        hover: '#598bff',
        active: '#36f',
        selected: '#36F',
      },
      background: {
        default: '#edf1f7',
      },
      // background: {
      //   paper: '#222b45',
      // },
    },
    typography: {
      fontFamily: [
        '"Rubik"',
        '"Noto Serif Sinhala"',
        '-apple-system',
        'BlinkMacSystemFont',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
    overrides: {
      MuiTableRow: {
        root: {
          textColor: 'rgba(0, 0, 0, 0.26)',
        },
      },
      MuiAppBar: {
        root: {
          boxShadow: '0 0.5rem 1rem 0 rgba(44, 51, 73, .1)',
        },
        colorPrimary: {
          backgroundColor: '#fff',
          color: '#000',
        },
      },
      MuiIconButton: {
        root: {
          color: '#8f9bb3',
        },
      },
      MuiCard: {
        root: {
          boxShadow: '0 0.5rem 1rem 0 rgba(44, 51, 73, .1)',
        },
      },

      MuiCssBaseline: {
        '@global': {
          // '@font-face': [notoSerifSinhalaBold, notoSerifSinhalaRegular],
          a: {
            color: 'inherit',
            textDecoration: 'none',
          },
          body: {
            margin: 0,
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
          },
        },
      },
    },
  });

  theme.overrides = {
    ...theme.overrides,
    MuiBadge: {
      colorSecondary: {
        backgroundColor: theme.palette.success.main,
      },
    },
    MuiCardHeader: {
      root: {
        padding: theme.spacing(0, 0, 1, 0),
        borderBottom: '1px solid #edf1f7',
      },
    },
    MuiTableCell: {
      root: {
        padding: theme.spacing(1, 1),
      },
      head: {
        padding: theme.spacing(2, 1),
      },
    },

    MuiTableBody: {
      root: {
        color: theme.palette.text.secondary,
      },
    },
  };

  return responsiveFontSizes(theme);
};

const theme = getTheme();

export default theme;
