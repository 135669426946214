import React from 'react';
import HomeIcon from '@material-ui/icons/HomeRounded';
import ReceiptIcon from '@material-ui/icons/Receipt';
import InvoiceIcon from '@material-ui/icons/FileCopy';
import ItemIcon from '@material-ui/icons/LocalOffer';
import CustomerIcon from '@material-ui/icons/PersonRounded';
import UserIcon from '@material-ui/icons/AccountCircle';
import CategoryIcon from '@material-ui/icons/Category';
import StoreIcon from '@material-ui/icons/Store';

export const DrawerLinks = [
  {
    id: 'home',
    name: 'Home',
    icon: <HomeIcon />,
    to: '/',
  },
  {
    id: 'order',
    name: 'Order',
    icon: <ReceiptIcon />,
    to: '/orders',
  },
  {
    id: 'invoice',
    name: 'Invoice',
    icon: <InvoiceIcon />,
    to: '/invoices',
  },
  {
    id: 'item-management',
    name: 'Items',
    icon: <ItemIcon />,
    to: '/item-management',
  },
  {
    id: 'category-management',
    name: 'Categories',
    icon: <CategoryIcon />,
    to: '/category-management',
  },
  {
    id: 'customer-management',
    name: 'Customers',
    icon: <CustomerIcon />,
    to: '/customer-management',
  },
  {
    id: 'my-shop',
    name: 'My Shop',
    icon: <StoreIcon />,
    to: '/my-shop',
  },
  {
    id: 'user-management',
    name: 'Users',
    icon: <UserIcon />,
    to: '/user-management',
  },
];

export const AdminLinks = [
  {
    id: 'shop-management',
    name: 'Shops',
    icon: <StoreIcon />,
    to: '/shop-management',
  },
];
