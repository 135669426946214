import React, { Suspense } from 'react';
import 'typeface-rubik';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import FullScreenLoading from './components/loading/fullscreen-loading';
import { client } from './config/apollo';
import ScrollToTop from './components/routing/scroll-to-top';
import TheApp from './the-app';
import theme from './components/theme';
import { AuthProvider } from './auth';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <AuthProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Suspense fallback={<FullScreenLoading />}>
              <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}>
                <Router>
                  <ScrollToTop />
                  <TheApp />
                </Router>
              </SnackbarProvider>
            </Suspense>
          </MuiPickersUtilsProvider>
        </AuthProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;
