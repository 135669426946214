import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { Link as RouteLink, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import Copyright from './copyright';
import { useAuth } from '../../context/auth';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface LoginFormData {
  email: string;
  password: string;
}

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(8).required(),
});

interface Props {
  from: { pathname: string };
}

const LoginForm: React.FC<Props> = ({ from }) => {
  const classes = useStyles();
  let history = useHistory();
  let { login } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, errors, control, formState } = useForm<LoginFormData>({ validationSchema: schema });
  const onSubmit = async (input: LoginFormData) => {
    const isLoggedIn = await login(input.email, input.password);

    if (isLoggedIn) {
      history.replace(from);
    } else {
      enqueueSnackbar('Login error!', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      });
    }
    return;
  };
  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
      <Controller
        as={TextField}
        variant="outlined"
        name="email"
        margin="normal"
        required
        fullWidth
        label="Email Address"
        autoComplete="email"
        control={control}
        defaultValue=""
        helperText={errors.email?.message}
        error={errors.email ? true : false}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          inputMode: 'email',
        }}
      />
      <Controller
        as={TextField}
        variant="outlined"
        name="password"
        margin="normal"
        required
        fullWidth
        label="Password"
        type="password"
        control={control}
        defaultValue=""
        helperText={errors.password?.message}
        error={errors.password ? true : false}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={formState.isSubmitting}>
        Login
      </Button>
      <Grid container>
        <Grid item xs>
          <Link component={RouteLink} to="/forgot-password" variant="body2">
            Forgot password?
          </Link>
        </Grid>
        {/* <Grid item>
          <Link href="#" variant="body2">
            {"Don't have an account? Sign Up"}
          </Link>
        </Grid> */}
      </Grid>
      <Box mt={5}>
        <Copyright />
      </Box>
    </form>
  );
};

export default LoginForm;
