import { ApolloClient } from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink, fromPromise } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import config from '.';
import Storage from '../auth/storage';
import { refreshTokenRequest } from '../auth/helper';

const cache = new InMemoryCache();
const link = new HttpLink({
  uri: config.app.graphQLEndpoint,
});

const authLink = setContext((_, { headers }) => {
  const token = Storage.getAuthToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      console.log(err);
      if (err.message.startsWith('Refresh Token Error!')) {
        Storage.removeAuth();
      } else if (err.message.startsWith('Access denied!')) {
        const rToken = Storage.getRefreshToken();
        if (rToken === undefined) return;
        return fromPromise(
          refreshTokenRequest(rToken)
            .then((response) => {
              if (response === null) {
                Storage.removeAuth();
                return;
              }
              Storage.saveTokens(response.token, response.refreshToken);
              operation.setContext(({ headers = {} }) => ({
                headers: {
                  // Re-add old headers
                  ...headers,
                  // Switch out old access token for new one
                  authorization: `Bearer ${response?.token}` || null,
                },
              }));
              return response.token;
            })
            .catch((error) => {
              // Handle token refresh errors e.g clear stored tokens, redirect to login, ...
              console.log(error);
              return;
            }),
        )
          .filter((value) => Boolean(value))
          .flatMap(() => {
            // retry the request, returning the new observable
            return forward(operation);
          });
      }
    }
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

export const client = new ApolloClient({
  cache: cache,
  link: ApolloLink.from([authLink, errorLink, link]),
  queryDeduplication: false,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});
