import * as React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Loading from './loading';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

interface Props {
  isOpen?: boolean;
}

const BackDropWithIndicator: React.FC<Props> = ({ isOpen }) => {
  const classes = useStyles();
  return (
    <Backdrop open={isOpen ?? false} className={classes.root}>
      <Loading color="#fff" />
    </Backdrop>
  );
};

export default BackDropWithIndicator;
