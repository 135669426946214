import React from 'react';
import Typography from '@material-ui/core/Typography';
import LoginForm from './login-form';

import { useLocation } from 'react-router-dom';

interface Props {
  imageCategory?: string;
}

const Login: React.FC<Props> = () => {
  let location = useLocation();

  let { from } = (location.state as any) || { from: { pathname: '/' } };

  return (
    <>
      <Typography component="h3" variant="h6">
        Login
      </Typography>
      <LoginForm from={from} />
    </>
  );
};

Login.defaultProps = {
  imageCategory: 'random',
};

export default Login;
