import React, { Suspense } from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import RandomImage from '../../components/random-image';
import GeniusLogo from '../../components/genius-logo';
import FullScreenLoading from '../../components/loading/fullscreen-loading';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    backgroundColor: '#edf1f7',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  version: {
    marginBottom: theme.spacing(3),
  },
  avatar: {
    backgroundColor: '#fff',
    boxShadow: '0.2rem 0.4rem 0.9rem 0.2rem rgba(44, 51, 73, .2)',
    margin: theme.spacing(1, 0, 2, 0),
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

interface Props {
  imageCategory?: string;
}

const AuthLayout: React.FC<Props> = ({ children, imageCategory }) => {
  const classes = useStyles();

  return (
    <Suspense fallback={<FullScreenLoading />}>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Box clone order={{ xs: 2, sm: 1 }}>
          <Grid item xs={12} sm={7} md={5} lg={3} xl={2} component={Paper} elevation={6} square>
            <div className={classes.paper}>
              <Avatar className={classes.avatar} alt="Genius Soft" variant="rounded">
                <GeniusLogo width="2.1em" height="2.1em" />
              </Avatar>
              <Typography component="h1" variant="h4" align="left">
                Genius Home Delivery
              </Typography>
              <Typography component="h2" variant="h5" color={'secondary'} className={classes.version} align="left">
                Retail Version
              </Typography>
              {children}
            </div>
          </Grid>
        </Box>
        <Box clone order={{ xs: 1, sm: 2 }}>
          <Grid item xs={12} sm={5} md={7} lg={9} xl={10}>
            <RandomImage imageCategory={imageCategory} />
          </Grid>
        </Box>
      </Grid>
    </Suspense>
  );
};

AuthLayout.defaultProps = {
  imageCategory: 'random',
};

export default AuthLayout;
