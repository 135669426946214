import React, { useState } from 'react';

interface DrawerContext {
  open: boolean;
  toggle: () => void;
  setOpen: (open: boolean) => void;
}

const DrawerContext = React.createContext<DrawerContext>({ open: false, toggle: () => {}, setOpen: () => {} });

const DrawerProvider: React.FC = (props) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);

  return <DrawerContext.Provider value={{ open, toggle, setOpen }} {...props} />;
};

export const useDrawer = () => React.useContext(DrawerContext);

export default DrawerProvider;
