import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = (imageCategory: string = 'shopping') =>
  makeStyles((theme) => ({
    image: {
      height: '100%',
      width: '100%',
      backgroundImage: `url(https://source.unsplash.com/featured/?${imageCategory})`,
      backgroundRepeat: 'no-repeat',
      backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: '3rem',
    },
  }))();

interface Props {
  imageCategory?: string;
}

const RandomImage: React.FC<Props> = ({ imageCategory }) => {
  const classes = useStyles(imageCategory);
  return <div className={classes.image}></div>;
};

export default RandomImage;
